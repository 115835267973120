<template>
  <div>
    <!-- About -->
    <div class="pb-0 pb-sm-2">
      <h1 class="title title--h1 first-title title__separate">
        {{ this.heading.aboutme }}
      </h1>
      <span style="white-space: pre-wrap">{{ this.about.data.content }}</span>
    </div>
    <!-- About -->

    <!-- Scopes -->
    <div class="mt-4">
      <h2 class="title title--h3">{{ this.heading.scope }}</h2>

      <div class="row">
        <div
          v-for="scope in scopes.data"
          :key="scope.id"
          class="col-12 col-lg-6"
        >
          <div class="case-item">
            <img
              v-if="scope.image"
              :src="scope.image.url"
              :alt="scope.image.alternativeText"
              width="40"
              height="40"
            />
            <div class="mt-3">
              <h3 class="title title--h4">{{ scope.title }}</h3>
              <p class="case-item__caption">
                {{ scope.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Scopes -->

    <!-- Clients -->
    <div class="mt-4">
      <h2 class="title title--h3">{{ this.heading.client }}</h2>

      <div class="swiper-container js-carousel-clients">
        <div class="swiper-wrapper">
          <div v-for="client in clients.data" :key="client.id">
            <!-- <a
              href="#"
              @click.prevent="openUrl(client.url)"
              :title="client.title"
            > -->
            <img
              v-if="client.image"
              width="200"
              height="93"
              :src="getImageUrl(client.image)"
              :alt="getImageAlt(client.image)"
            />
            <!-- </a> -->
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
    <!-- Clients -->
  </div>
</template>

<script>
import { mobileDetection } from "../mixins/mobileDetection"
import { helper } from "../mixins/helper"
import aboutJson from "../data/about.json"
import scopeJson from "../data/scopes.json"
import clientJson from "../data/clients.json"

export default {
  name: "About",
  mixins: [mobileDetection, helper],
  data() {
    return {
      page: {
        meta: {
          title: "Adityacprtm | DevOps - SRE - Cloud Engineer",
          description:
            "Get to know me, Aditya Chamim Pratama. An enthusiastic newbie, DevOps - SRE - Cloud Engineer.",
        },
      },
      about: {
        data: aboutJson.data,
      },
      scopes: {
        data: scopeJson.data,
      },
      clients: {
        data: clientJson.data,
      },
      heading: {
        aboutme: "About Me",
        scope: "What I'm Doing",
        client: "Clients",
      },
    }
  },
  created() {},
  methods: {},
  metaInfo() {
    return {
      title: this.page.meta
        ? this.page.meta.title
        : "Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
      ],
    }
  },
}
</script>
