<template>
  <div>
    <div class="content">
      <router-link to="/blog" class="badge badge-primary sticky-button">
        <em class="font-icon icon-arrow-left"></em> {{ text.returnBtn }}
      </router-link>
      <div class="pb - 3">
        <header class="header-post">
          <h1 class="title title--h1">{{ blog.title }}</h1>
          <p>{{ monthDayYearFormat(blog.publishedAt) }}</p>
          <div class="caption-post">
            <p>
              {{ blog.meta.description }}
            </p>
          </div>
          <div class="header-post__image-wrap">
            <img
              class="cover lazyload"
              :src="getImageUrl(this.blog.thumbnail, 'medium')"
              :alt="getImageAlt(this.blog.thumbnail)"
            />
          </div>
          <p v-if="blog.series" class="box__border series__part">
            {{ text.partOfSeries }}
            <router-link :to="'/series/' + blog.series.slug">
              {{ blog.series.title }}
            </router-link>
            series
          </p>
        </header>
        <div class="caption-post">
          <vue-markdown :source="blog.content" />
        </div>
        <footer class="footer-post">
          <a
            class="footer-post__share"
            href="https://www.github.com/adityacprtm"
            target="_blank"
            rel="noopener"
          >
            <em class="font-icon icon-github"></em> <span>Github</span>
          </a>
          <a
            class="footer-post__share"
            href="https://www.twitter.com/adityacprtm"
            target="_blank"
            rel="noopener"
          >
            <em class="font-icon icon-twitter"></em> <span>Twitter</span>
          </a>
          <a
            class="footer-post__share"
            href="https://www.linkedin.com/in/adityacprtm"
            target="_blank"
            rel="noopener"
          >
            <em class="font-icon icon-linkedin"></em> <span>Linkedin</span>
          </a>
        </footer>
      </div>

      <div class="box-inner box-inner--rounded">
        <h2 class="title title--h3">
          <DisqusCount
            shortname="adityacprtm"
            :identifier="pageConfig.identifier"
          />
        </h2>
        <Disqus :pageConfig="pageConfig" shortname="adityacprtm" lang="Id_ID" />
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render"
import { dateFormat } from "../mixins/dateFormat"
import { helper } from "../mixins/helper"
import { Disqus, DisqusCount } from "vue-disqus"
import blogsJson from "../data/blogs.json"

export default {
  name: "BlogDetail",
  mixins: [dateFormat, helper],
  components: {
    VueMarkdown,
    Disqus,
    DisqusCount,
  },
  data() {
    return {
      blog: {},
      isLoading: true,
      pageConfig: {
        identifier: this.$route.path,
      },
      text: {
        returnBtn: "Return to Blog List",
        partOfSeries: "This blog is part of",
      },
    }
  },
  created() {
    this.getBlogDetail()
  },
  methods: {
    getBlogDetail() {
      this.blog = blogsJson.data.filter(
        (it) => it.slug === this.$route.params.slug
      )[0]
    },
  },
  metaInfo() {
    return {
      title: this.blog.meta
        ? this.blog.meta.title + " | Blog | Adityacprtm"
        : "Blog | Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "twitter:title",
          property: "twitter:title",
          content: this.blog.meta
            ? this.blog.meta.title + " | Blog | Adityacprtm"
            : "Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          property: "twitter:description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "twitter:image",
          property: "twitter:image",
          content: this.getImageUrl(this.blog.image),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.blog.meta
            ? this.blog.meta.title + " | Blog | Adityacprtm"
            : "Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.getImageUrl(this.blog.image),
        },
      ],
    }
  },
}
</script>
