<template>
  <div>
    <div class="pb-2">
      <h1 class="title title--h1 first-title title__separate">Journey</h1>
    </div>

    <!-- Journey -->
    <div class="row">
      <div class="col-12">
        <div class="timeline">
          <article
            v-for="journey in journeys.data"
            :key="journey.id"
            class="timeline__item"
          >
            <h5 class="title title--h5 timeline__title">
              {{ monthDayYearFormat(journey.date) }}
              <span style="font-weight: normal">
                <vue-markdown :source="journey.description" />
              </span>
            </h5>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render"
import { dateFormat } from "../mixins/dateFormat"
import { mobileDetection } from "../mixins/mobileDetection"
import journeysJson from "../data/journeys.json"

export default {
  name: "Journey",
  mixins: [dateFormat, mobileDetection],
  data() {
    return {
      page: {
        meta: {
          title: "Journey | Adityacprtm | DevOps - SRE - Cloud Engineer",
          description:
            "Look at my journey in building this portfolio website. Start from hard code to dynamic website.",
        },
      },
      journeys: {
        data: journeysJson.data,
      },
    }
  },
  components: {
    VueMarkdown,
  },
  created() {},
  methods: {},
  metaInfo() {
    return {
      title: this.page.meta
        ? this.page.meta.title
        : "Journey | Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Journey.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Journey | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Journey.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Journey | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Journey.",
        },
      ],
    }
  },
}
</script>
