import Vue from "vue"
import VueRouter from "vue-router"
import Meta from "vue-meta"
import axios from "axios"
import VueAxios from "vue-axios"
import LoadScript from "vue-plugin-load-script"

Vue.use(VueRouter)
Vue.use(Meta)
Vue.use(VueAxios, axios)
Vue.use(LoadScript)

// axios global config
// axios.defaults.baseURL = process.env.VUE_APP_API
// axios.defaults.headers.common["Authorization"] = "Bearer " + process.env.VUE_APP_API_TOKEN

const routes = [
  {
    path: "/",
    name: "About",
    component: function () {
      return import(/* webpackChunkName: "nYehN9pv7Lyp" */ "../views/About.vue")
    },
  },
  {
    path: "/resume",
    name: "Resume",
    component: function () {
      return import(
        /* webpackChunkName: "nYehN9pv7Lyp" */ "../views/Resume.vue"
      )
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: function () {
      return import(
        /* webpackChunkName: "nYehN9pv7Lyp" */ "../views/Portfolio.vue"
      )
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: function () {
      return import(
        /* webpackChunkName: "nYehN9pv7Lyp" */ "../views/Contact.vue"
      )
    },
  },
  {
    path: "/journey",
    name: "Journey",
    component: function () {
      return import(
        /* webpackChunkName: "nYehN9pv7Lyp" */ "../views/Journey.vue"
      )
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: function () {
      return import(/* webpackChunkName: "nYehN9pv7Lyp" */ "../views/Blog.vue")
    },
  },
  {
    path: "/blog/:slug",
    name: "BlogDetail",
    component: function () {
      return import(
        /* webpackChunkName: "nYehN9pv7Lyp" */ "../views/BlogDetail.vue"
      )
    },
  },
  {
    path: "/series",
    name: "Series",
    component: function () {
      return import(
        /* webpackChunkName: "nYehN9pv7Lyp" */ "../views/Series.vue"
      )
    },
  },
  {
    path: "/series/:slug",
    name: "SeriesList",
    component: function () {
      return import(
        /* webpackChunkName: "nYehN9pv7Lyp" */ "../views/SeriesList.vue"
      )
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: function () {
      return import(/* webpackChunkName: "nYehN9pv7Lyp" */ "../views/404.vue")
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash, behavior: "smooth" }
    } else {
      return { x: 0, y: 0, behavior: "smooth" }
    }
  },
})

export default router
