<template>
  <div class="content">
    <div class="section mt-0">
      <h1 class="title title--h1 title__separate">{{ heading.blog }}</h1>
    </div>
    <div>
      <div class="news-grid section">
        <article v-for="blog in blogs" :key="blog.id" class="news-item box">
          <div class="news-item__image-wrap overlay overlay--45">
            <div class="news-item__date">
              {{ dayFormat(blog.publishedAt) }}
              <span>
                {{ monthFormat(blog.publishedAt) }}
              </span>
            </div>
            <router-link :to="'/blog/' + blog.slug" class="news-item__link" />
            <img
              class="cover lazyload"
              :src="getImageUrl(blog.thumbnail, 'small')"
              :alt="getImageAlt(blog.thumbnail)"
            />
          </div>
          <div class="news-item__caption">
            <h3 class="title title--h4">
              <v-clamp autoresize :max-lines="2">
                {{ blog.title }}
              </v-clamp>
            </h3>
            <v-clamp autoresize tag="p" :max-lines="2">
              {{ blog.meta.description }}
            </v-clamp>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp"
import { dateFormat } from "../mixins/dateFormat"
import { helper } from "../mixins/helper"
import blogsJson from "../data/blogs.json"

export default {
  name: "Blog",
  mixins: [dateFormat, helper],
  components: {
    VClamp,
  },
  data() {
    return {
      blogs: blogsJson.data,
      page: {
        meta: {
          title: "Blog | Adityacprtm | DevOps - SRE - Cloud Engineer",
          description:
            "Read some notes that I wrote while developing something, for sharing and personal reference later. Blog Adityacprtm.",
        },
      },
      heading: {
        blog: "Blog",
      },
    }
  },
  created() {},
  methods: {},
  metaInfo() {
    return {
      title: this.page.meta
        ? this.page.meta.title
        : "Blog | Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Blog | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Blog | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama posts.",
        },
      ],
    }
  },
}
</script>
