<template>
  <div>
    <div class="pb-2">
      <h1 class="title title--h1 first-title title__separate">
        {{ this.heading.resume }}
      </h1>
    </div>

    <!-- Experience -->
    <div class="row">
      <div class="col">
        <h2 class="title title--h3">
          <img
            class="title-icon"
            src="../assets/icons/icon-experience.svg"
            alt="experience"
          />
          {{ this.heading.experience }}
        </h2>
        <div class="timeline">
          <article
            v-for="experience in experiences.data"
            :key="experience.id"
            class="timeline__item"
          >
            <h5 class="title title--h5 timeline__title">
              {{ experience.institution }}
              <small> {{ text.asExperience }} </small>
              {{ experience.position }}
            </h5>
            <span class="timeline__period">
              {{ monthYearFormat(experience.startDate) }}
              -
              {{
                experience.endDate
                  ? monthYearFormat(experience.endDate)
                  : "Present"
              }}
            </span>
            <p class="timeline__description">
              <span v-if="experience.description" style="white-space: pre-wrap"
                >{{ experience.description }}
                <br />
              </span>
              <strong>{{ experience.location }}</strong>
            </p>
          </article>
        </div>
      </div>
    </div>
    <!-- Experience -->

    <!-- Education -->
    <div class="row mt-4">
      <!-- Education Formal -->
      <div class="col-sm">
        <h2 class="title title--h3">
          <img
            class="title-icon"
            src="../assets/icons/icon-education.svg"
            alt="education"
          />
          {{ this.heading.education }}
        </h2>
        <div class="timeline">
          <article
            v-for="education in educationFormal.data"
            :key="education.id"
            class="timeline__item"
          >
            <h5 class="title title--h5 timeline__title">
              {{ education.institution }}
            </h5>
            <span class="timeline__period">
              {{ monthYearFormat(education.startDate) }} -
              {{
                education.endDate
                  ? monthYearFormat(education.endDate)
                  : "Present"
              }}
            </span>
            <p class="timeline__description">
              <span v-if="education.description" style="white-space: pre-wrap"
                >{{ education.description }}
                <br />
              </span>
              {{ education.location }}
            </p>
          </article>
        </div>
      </div>
      <!-- Education Formal -->

      <!-- Education Non Formal -->
      <div :class="isMobile() ? 'col-sm mt-4' : 'col-sm'">
        <h2 class="title title--h3">
          <img
            class="title-icon"
            src="../assets/icons/icon-education.svg"
            alt="education"
          />
          {{ this.heading.education2 }}
        </h2>
        <div class="timeline">
          <article
            v-for="education in educationNonformal.data"
            :key="education.id"
            class="timeline__item"
          >
            <h5 class="title title--h5 timeline__title">
              {{ education.institution }}
            </h5>
            <span class="timeline__period">
              {{ monthYearFormat(education.startDate) }} -
              {{
                education.endDate
                  ? monthYearFormat(education.endDate)
                  : "Present"
              }}
              ( {{ education.hours }} Hours)
            </span>
            <p class="timeline__description">
              <span v-if="education.description" style="white-space: pre-wrap"
                >{{ education.description }}
                <br />
              </span>
              {{ education.location }}
            </p>
          </article>
        </div>
      </div>
      <!-- Education Non Formal -->
    </div>
    <!-- Education -->

    <!-- Skills -->
    <div class="row mt-4">
      <div class="col-12">
        <h2 class="title title--h3">{{ this.heading.skills }}</h2>
        <div class="box box__border">
          <div>
            <div
              v-for="skill in skills.data"
              v-bind:key="skill.id"
              class="progress"
            >
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="
                  'width: ' + getSkillValue(skill.level) + '%; z-index: 2;'
                "
              >
                <div class="progress-text">
                  <span> {{ skill.title }} </span>
                  <span> {{ isMobile() ? "" : skill.level }} </span>
                </div>
              </div>
              <div class="progress-text">
                <span> {{ skill.title }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Skills -->

    <!-- Code Skills -->
    <div class="row mt-4">
      <div class="col-12">
        <h2 class="title title--h3">{{ this.heading.code_skills }}</h2>
        <div class="box box__border">
          <div>
            <div
              v-for="skill in codeSkills.data"
              v-bind:key="skill.id"
              class="progress"
            >
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="
                  'width: ' + getSkillValue(skill.level) + '%; z-index: 2;'
                "
              >
                <div class="progress-text">
                  <span> {{ skill.title }} </span>
                  <span> {{ isMobile() ? "" : skill.level }} </span>
                </div>
              </div>
              <div class="progress-text">
                <span> {{ skill.title }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CodeSkills -->
  </div>
</template>

<script>
import { dateFormat } from "../mixins/dateFormat"
import { mobileDetection } from "../mixins/mobileDetection"
import experiencesJson from "../data/experiences.json"
import formalEducationsJson from "../data/formal-educations.json"
import informalEducationsJson from "../data/informal-educations.json"
import skillsJson from "../data/skills.json"
import codingSkillsJson from "../data/coding-skills.json"

export default {
  name: "Resume",
  mixins: [dateFormat, mobileDetection],
  data() {
    return {
      page: {
        meta: {
          title: "Resume | Adityacprtm | DevOps - SRE - Cloud Engineer",
          description:
            "Check out some info on my Resume to get to know me more deeply. Resume Adityacprtm.",
        },
      },
      educationFormal: {
        data: formalEducationsJson.data,
      },
      educationNonformal: {
        data: informalEducationsJson.data,
      },
      experiences: {
        data: experiencesJson.data,
      },
      skills: {
        data: skillsJson.data,
      },
      codeSkills: {
        data: codingSkillsJson.data,
      },
      heading: {
        resume: "Resume",
        education: "Education",
        education2: "Nonformal Education",
        experience: "Experience",
        skills: "Skills",
        code_skills: "Code Skills",
      },
      text: {
        asExperience: "as",
      },
    }
  },
  created() {},
  methods: {
    getSkillValue: function (level) {
      // Beginner, Intermediate, Expert
      if (level == "Beginner") {
        return 33
      } else if (level == "Intermediate") {
        return 66
      }
      return 100
    },
  },
  metaInfo() {
    return {
      title: this.page.meta
        ? this.page.meta.title
        : "Resume | Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Resume.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Resume | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Resume.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Resume | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Resume.",
        },
      ],
    }
  },
}
</script>
