export const mobileDetection = {
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      }
      return false
    },
  },
}
