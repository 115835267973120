<template>
  <div>
    <div class="pb-2">
      <h1 class="title title--h1 first-title title__separate">
        {{ heading.portfolio }}
      </h1>
    </div>

    <div>
      <!-- Filter -->
      <div class="select">
        <span class="placeholder">Select category</span>
        <ul class="filter">
          <li class="filter__item">Category</li>
          <li class="filter__item active" data-filter="*">
            <a class="filter__link active" href="#filter">
              {{ text.allCategory }}
            </a>
          </li>
          <!-- Loop start -->
          <li
            v-for="category in categories"
            :key="category.id"
            class="filter__item"
            :data-filter="'.category-' + category.name"
          >
            <a class="filter__link" href="#filter">
              {{ category.name }}
            </a>
          </li>
          <!-- loop end -->
        </ul>
        <input type="hidden" name="changemetoo" />
      </div>
      <!-- Filter -->

      <!-- Gallery -->
      <div class="gallery-grid-two js-grid js-filter-container">
        <div class="gutter-sizer"></div>

        <figure
          v-for="portfolio in portfolios"
          :key="portfolio.id"
          class="gallery-grid__item"
          :class="'category-' + portfolio.portfolio_category.name"
        >
          <div class="box-gallery-grid__item box__border">
            <div class="gallery-grid__image-wrap">
              <div class="portfolio-item__category">
                <span>{{ portfolio.portfolio_category.name }}</span>
              </div>
              <img
                class="gallery-grid__image cover lazyload"
                :src="getImageUrl(portfolio.image, 'medium')"
                :alt="getImageAlt(portfolio.image)"
                data-zoom
              />
              <div
                v-if="portfolio.descriptionHeader || portfolio.descriptionBody"
                class="caption"
              >
                <div class="blur"></div>
                <div class="caption-text">
                  <h1 v-if="portfolio.descriptionHeader">
                    {{ portfolio.descriptionHeader }}
                  </h1>
                  <p v-if="portfolio.descriptionBody">
                    {{ portfolio.descriptionBody }}
                  </p>
                </div>
              </div>
            </div>
            <figcaption class="gallery-grid__caption">
              <h4 class="title title--h4 gallery-grid__title">
                {{ portfolio.title }}
              </h4>
              <a
                v-for="link in portfolio.links"
                :key="link.id"
                class="link-btn mt-2"
                href="#"
                @click.prevent="openUrl(link.url)"
              >
                {{ link.title }}
              </a>
              <!-- <span class="gallery-grid__category">
                {{ portfolio.portfolio_category.name }}
              </span> -->
            </figcaption>
          </div>
        </figure>
      </div>
      <!-- Gallery -->
    </div>
  </div>
</template>

<script>
import { helper } from "../mixins/helper"
import portfolioCategoriesJson from "../data/portfolio-categories.json"
import portfoliosJson from "../data/portfolios.json"

export default {
  name: "Portfolio",
  mixins: [helper],
  data() {
    return {
      page: {
        meta: {
          title: "Portfolio | Adityacprtm | DevOps - SRE - Cloud Engineer",
          description:
            "View some of the fun projects I have developed and certifications I have achieved. Some of the technologies used are Node.js, Laravel, Cloud and more. Portfolio Adityacprtm.",
        },
      },
      categories: portfolioCategoriesJson.data,
      portfolios: [],
      heading: {
        portfolio: "Portfolio",
      },
      text: {
        allCategory: "All",
      },
    }
  },
  created() {
    this.$loadScript("/js/jquery-3.4.1.min.js").then(() => {
      this.$loadScript("/js/plugins.min.js").then(() => {
        this.$loadScript("/js/portfolio.js").then(() => {
          this.portfolios = this.shuffleArray(portfoliosJson.data)
        })
      })
    })
  },
  methods: {
    shuffleArray: function (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },
  },
  metaInfo() {
    return {
      title: this.page.meta
        ? this.page.meta.title
        : "Portfolio | Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Portfolio.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Portfolio | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Portfolio.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.page.meta
            ? this.page.meta.title
            : "Portfolio | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.page.meta
            ? this.page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Portfolio.",
        },
      ],
    }
  },
}
</script>
