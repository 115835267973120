import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import * as Sentry from "@sentry/vue"
// import { Integrations } from "@sentry/tracing"

localStorage.removeItem("locale_adityacprtm")

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_DSN_SENTRY,
    integrations: [
      // new Integrations.BrowserTracing(),
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // Session Replay
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

// Importing the global css file
import "@/assets/css/style.css"
import "@/assets/css/style-dark.css"

new Vue({
  router,
  render: function (h) {
    return h(App)
  },
}).$mount("#app")
