var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"section mt-0"},[_c('h1',{staticClass:"title title--h1 title__separate"},[_vm._v("Blog Series")]),_c('router-link',{staticClass:"badge badge-primary mb-4",attrs:{"to":"/series"}},[_c('em',{staticClass:"font-icon icon-arrow-left"}),_vm._v(" "+_vm._s(_vm.text.returnBtn)+" ")]),_c('h3',{staticClass:"title title--h3 text-center"},[_vm._v(_vm._s(_vm.series.title))]),_c('br')],1),_c('div',{staticClass:"news-grid section"},[(!_vm.series.length)?_c('div',{staticStyle:{"margin":"auto"}},[_c('p',[_vm._v("Oops! This series is coming soon!")])]):_vm._e(),_vm._l((_vm.series),function(blog,index){return _c('article',{key:blog.id,staticClass:"news-item box"},[_c('div',{staticClass:"news-item__image-wrap overlay overlay--45"},[_c('div',{staticClass:"news-item__date"},[_vm._v(" "+_vm._s(_vm.dayFormat(blog.publishedAt))+" "),_c('span',[_vm._v(" "+_vm._s(_vm.monthFormat(blog.publishedAt))+" ")])]),_c('div',{staticClass:"news-item__part"},[_c('span',[_vm._v(" "+_vm._s(_vm.text.part)+" ")]),_vm._v(" "+_vm._s(index + 1)+" ")]),_c('router-link',{staticClass:"news-item__link",attrs:{"to":'/blog/' + blog.slug}}),_c('img',{staticClass:"cover lazyload",attrs:{"src":blog.thumbnail
                ? blog.thumbnail.formats.small
                  ? blog.thumbnail.formats.small.url
                  : blog.thumbnail.url
                : '/static/social.jpg',"alt":blog.thumbnail
                ? blog.thumbnail.alternativeText
                  ? blog.thumbnail.alternativeText
                  : blog.title
                : 'Adityacprtm'}})],1),_c('div',{staticClass:"news-item__caption"},[_c('h3',{staticClass:"title title--h4"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":2}},[_vm._v(" "+_vm._s(blog.title)+" ")])],1),_c('v-clamp',{attrs:{"autoresize":"","tag":"p","max-lines":2}},[_vm._v(" "+_vm._s(blog.meta.description)+" ")])],1)])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }