<template>
  <div>
    <div class="content">
      <div class="section mt-0">
        <h1 class="title title--h1 title__separate">
          {{ this.heading.seriesList }}
        </h1>
      </div>

      <div class="news-grid section">
        <article
          v-for="series in series"
          :key="series.id"
          class="news-item box"
        >
          <div class="news-item__caption series__caption">
            <router-link :to="'/series/' + series.slug">
              <v-clamp
                autoresize
                :max-lines="2"
                tag="h3"
                class="title title--h4"
              >
                {{ series.title }}
              </v-clamp>
            </router-link>
            <v-clamp autoresize tag="p" :max-lines="2">
              <!-- {{ series.pagination.total }} {{ text.partSeries }} -->
              last update: {{ monthDayYearFormat(series.updatedAt) }}
            </v-clamp>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp"
import { dateFormat } from "../mixins/dateFormat"
import seriesJson from "../data/series.json"

export default {
  name: "Series",
  mixins: [dateFormat],
  components: {
    VClamp,
  },
  data() {
    return {
      series: seriesJson.data,
      seriesMeta: {},
      isLoading: true,
      errorFetch: "",
      heading: {
        seriesList: "Series List",
      },
      text: {
        partSeries: "Part Series",
      },
    }
  },
  created() {},
  methods: {},
  metaInfo() {
    return {
      title: "Blog Series | Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: "Blog Series | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Blog Series | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Get to know Aditya Chamim Pratama posts.",
        },
      ],
    }
  },
}
</script>
