export const helper = {
  methods: {
    // data json
    // size = null > original size
    getImageUrl(data, size = "") {
      if (data) {
        if (data.formats) {
          return data.formats[size] ? data.formats[size].url : data.url
        }
        return data.url
      } else {
        return "/static/social.jpg"
      }
    },
    // data json
    getImageAlt(data) {
      if (data) {
        return data.alternativeText ? data.alternativeText : data.title
      } else {
        return "Adityacprtm"
      }
    },
    openUrl(url) {
      window.open(url, "_blank", "noopener")
    },
  },
}
