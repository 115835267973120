<template>
  <div>
    <!-- <div class="pb-2">
      <h1 class="title title--h1 first-title title__separate">Opss!</h1>
    </div> -->

    <div class="row text-center">
      <div class="col">
        <p class="title title--h3">{{ title }}</p>
        <p>
          <span v-if="msg" class="with-errors">{{ msg }}<br /></span>
          <span v-if="code == '404'">
            {{ text.first }}
            <router-link to="/contact">{{ text.second }}</router-link
            >.
          </span>
          <span v-else>
            {{ text.third }}
            <a href="mailto:aditya@adityacprtm.dev">aditya@adityacprtm.dev</a>
          </span>
          <br />
          {{ text.fourth }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    title: String,
    msg: String,
    code: String,
  },
  data() {
    return {
      text: {
        first: "Looking for something you didn't find? Contact me",
        second: "here",
        third: "Please notify me by email at",
        fourth:
          "Or find one of the other contact methods listed to reach me that way.",
      },
    }
  },
}
</script>
