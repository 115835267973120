<template>
  <div>
    <div class="content">
      <div class="section mt-0">
        <h1 class="title title--h1 title__separate">Blog Series</h1>
        <router-link to="/series" class="badge badge-primary mb-4">
          <em class="font-icon icon-arrow-left"></em> {{ text.returnBtn }}
        </router-link>
        <h3 class="title title--h3 text-center">{{ series.title }}</h3>
        <br />
      </div>

      <div class="news-grid section">
        <div v-if="!series.length" style="margin: auto">
          <p>Oops! This series is coming soon!</p>
        </div>
        <article
          v-for="(blog, index) in series"
          :key="blog.id"
          class="news-item box"
        >
          <div class="news-item__image-wrap overlay overlay--45">
            <div class="news-item__date">
              {{ dayFormat(blog.publishedAt) }}
              <span>
                {{ monthFormat(blog.publishedAt) }}
              </span>
            </div>
            <div class="news-item__part">
              <span> {{ text.part }} </span>
              {{ index + 1 }}
            </div>
            <router-link :to="'/blog/' + blog.slug" class="news-item__link" />
            <img
              class="cover lazyload"
              :src="
                blog.thumbnail
                  ? blog.thumbnail.formats.small
                    ? blog.thumbnail.formats.small.url
                    : blog.thumbnail.url
                  : '/static/social.jpg'
              "
              :alt="
                blog.thumbnail
                  ? blog.thumbnail.alternativeText
                    ? blog.thumbnail.alternativeText
                    : blog.title
                  : 'Adityacprtm'
              "
            />
          </div>
          <div class="news-item__caption">
            <h3 class="title title--h4">
              <v-clamp autoresize :max-lines="2">
                {{ blog.title }}
              </v-clamp>
            </h3>
            <v-clamp autoresize tag="p" :max-lines="2">
              {{ blog.meta.description }}
            </v-clamp>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp"
import { dateFormat } from "../mixins/dateFormat"
import blogsJson from "../data/blogs.json"

export default {
  name: "SeriesList",
  mixins: [dateFormat],
  components: {
    VClamp,
  },
  data() {
    return {
      series: {},
      text: {
        returnBtn: "Return to Series List",
        part: "Part",
      },
    }
  },
  created() {
    this.getBlogSeries()
  },
  methods: {
    getBlogSeries() {
      this.series = blogsJson.data.filter(
        (it) => it.series != null && it.series.slug === this.$route.params.slug
      )
    },
  },
  metaInfo() {
    return {
      title: this.series.title
        ? this.series.title
        : "Blog Series | Adityacprtm | DevOps - SRE - Cloud Engineer",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.series.title
            ? this.series.title
            : "Blog Series by Adityacprtm.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.series.title
            ? this.series.title
            : "Blog Series | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.series.title
            ? this.series.title
            : "Blog Series by Adityacprtm.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.series.title
            ? this.series.title
            : "Blog | Adityacprtm | DevOps - SRE - Cloud Engineer",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.series.title
            ? this.series.title
            : "Blog Series by Adityacprtm.",
        },
      ],
    }
  },
}
</script>
