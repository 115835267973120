<template>
  <ErrorComponent title="404 | Not Found" code="404" />
</template>

<script>
import ErrorComponent from "@/components/Error"

export default {
  name: "NotFoundPage",
  components: {
    ErrorComponent,
  },
}
</script>
