export const dateFormat = {
  methods: {
    monthYearFormat(raw) {
      let locale = localStorage.locale_adityacprtm
        ? localStorage.locale_adityacprtm
        : "en"
      var options = {
        year: "numeric",
        month: "short",
      }
      return new Date(raw).toLocaleDateString(locale, options)
    },
    dayFormat(raw) {
      let locale = localStorage.locale_adityacprtm
        ? localStorage.locale_adityacprtm
        : "en"
      var options = {
        day: "numeric",
      }
      return new Date(raw).toLocaleDateString(locale, options)
    },
    monthFormat(raw) {
      let locale = localStorage.locale_adityacprtm
        ? localStorage.locale_adityacprtm
        : "en"
      var options = {
        month: "short",
      }
      return new Date(raw).toLocaleDateString(locale, options)
    },
    yearFormat(raw) {
      let locale = localStorage.locale_adityacprtm
        ? localStorage.locale_adityacprtm
        : "en"
      var options = {
        year: "numeric",
      }
      return new Date(raw).toLocaleDateString(locale, options)
    },
    monthDayYearFormat: function (raw) {
      let locale = localStorage.locale_adityacprtm
        ? localStorage.locale_adityacprtm
        : "en"
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
      return new Date(raw).toLocaleDateString(locale, options)
    },
  },
}
